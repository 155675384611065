<template>
  <BaseDialog :model-value="true" size-class="!max-w-xl" @update:model-value="$emit('close')">
    <template #header>
      <div class="flex w-full items-center border-b border-gray-800 px-2 pb-3">
        Notifications
        <div v-if="muted" class="ml-1.5 text-xs font-medium text-gray-400">(Chat is muted)</div>
      </div>
    </template>
    <template #default>
      <div class="flex-col items-center space-y-2 px-2">
        <div class="text-xs font-medium text-gray-300">Receive notifications for</div>

        <div class="mb-4 mt-3 flex">
          <input id="all" type="radio" v-model="notificationSelected" value="all" class="hidden" :disabled="muted" />
          <label for="all" class="flex cursor-pointer items-start text-sm font-medium text-gray-100">
            <span
              class="border-grey flex-no-shrink mr-2 mt-1 inline-block h-3 w-3 rounded-full border-2"
              :class="muted ? 'bg-white' : notificationSelected == 'all' ? 'bg-blue-600' : 'bg-white'"
            ></span>
            <div>
              <div>All new messages</div>
              <div class="text-xs font-medium leading-4 text-gray-400">Get notifications for all messages</div>
            </div>
          </label>
        </div>

        <div class="mb-4 flex flex-wrap">
          <input id="none" type="radio" :disabled="muted" v-model="notificationSelected" value="none" class="hidden" />
          <label for="none" class="flex cursor-pointer items-start text-sm font-medium text-gray-100">
            <span
              class="border-grey flex-no-shrink mr-2 mt-1 inline-block h-3 w-3 rounded-full border-2"
              :class="muted ? 'bg-gray-600' : notificationSelected == 'none' ? 'bg-blue-600' : 'bg-white'"
            ></span>
            <div>
              <div>Off</div>
              <div class="cursor-pointer text-xs font-medium leading-4 text-gray-400">Don't get any notifications</div>
            </div>
          </label>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex w-full justify-end space-x-2 px-2">
        <div class="flex items-center space-x-2">
          <BaseButton type="secondary" @press="$emit('close')">Cancel</BaseButton>
          <BaseButton
            :disabled="muted"
            @click="
              saveNotificationPreference([client.user.id], channel, notificationSelected);
              $emit('close');
            "
          >
            Save
          </BaseButton>
        </div>
      </div>
    </template>
  </BaseDialog>
</template>
<script setup>
import { ref, computed } from 'vue';
import { useMapGetter } from '@/store/map-state';
import { saveNotificationPreference } from '@/composeables/chat';
const { client, streamUser, mutedChannels } = useMapGetter();
const $emit = defineEmits(['close']);
const props = defineProps({
  channel: Object,
  user: Object
});
const notificationSelected = ref(
  props.channel.data.notification_users && props.channel.data.notification_users.includes(props.user.id)
    ? 'all'
    : 'none'
);

const muted = computed(() => {
  return mutedChannels.value.includes(props.channel.cid);
});
</script>
